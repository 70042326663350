*,
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #222222;
  overflow: hidden;
}

#overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

#btnPlay {
  border: 0;
  width: 120px;
  height: 48px;
  color: #222;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
